import { addBreadcrumb, Severity } from '@sentry/react'
import { History, LinkedAsset, Releases, Sort } from 'constants/filters'
import { ActionResponse, BoolType, ReviewStatus } from 'constants/resources'
import { captureError } from 'helpers/error'
import { initFilters } from 'helpers/filters'
import { removeEmpty } from 'helpers/objects'
import { filterByRating } from 'helpers/resources'
import { action, computed, makeObservable, observable } from 'mobx'
import qs from 'qs'
import {
  ApprovedResource,
  Rating,
  RejectedResource,
  ResourceState,
  ReviewedResource,
  ReviewFilter,
  ReviewItemFilter,
} from 'types'
import { BulkOperation } from 'types/operations'
import Api from 'util/api'
import { assertExhaustive, pushOrUpdate } from 'util/helpers'
import { GetApiV1ResourcesQueryParams, RejectionReason, Resource } from '../api'
import { DIContainer } from '../util/di'
import AuthStore from './AuthStore'
import ResourceStore from './ResourceStore'

type SubmitResponse = {
  failedIds: string[]
  succeededIds: string[]
}

export default class SubmittedReviewStore {
  api: Api
  AuthStore: AuthStore
  ResourceStore: ResourceStore
  state: ResourceState[]
  defaultState: ResourceState[] = ['submitted']
  defaultFilter: ReviewItemFilter = {
    sort: Sort.Oldest,
    license: undefined,
    file_type: [],
    category_ids: [],
    content_type: [],
    program_type: undefined,
    history: undefined,
    releases: undefined,
    ai_generated: [],
    linked_asset: undefined,
    auto_submitted: [],
    magic_metadata: [],
  }

  @observable filter: ReviewItemFilter = initFilters<ReviewItemFilter>(
    this.defaultFilter
  )
  @observable reviewFilters: ReviewFilter[] = [
    'all',
    1,
    2,
    3,
    4,
    5,
    'flagged',
    'rejected',
  ]
  @observable reviewedResources: ReviewedResource[] = []
  @observable totalPendingCount = 0
  @observable totalFilteredCount = 0
  @observable successfullySubmittedIds: string[] = []
  @observable approveAllModalVisibility = false
  @observable rejectAllModalVisibility = false
  @observable bulkOperations: BulkOperation[] = []
  @observable exitedSubmittedContributors: string[] = []

  constructor(container: DIContainer<Record<string, unknown>>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
    this.ResourceStore = container.find('ResourceStore')
    this.state = this.defaultState
  }

  @action reset = (): void => {
    this.resetFilters()
    this.setReviewedResources([])
    this.totalPendingCount = 0
    this.totalFilteredCount = 0
    this.successfullySubmittedIds = []
    this.ResourceStore.set([])
    this.ResourceStore.deselectAll()
    this.ResourceStore.setOriginal([])
  }

  @action setFilter = (filter: ReviewItemFilter, syncUrl = false): void => {
    this.ResourceStore.deselectAll()
    this.filter = filter
    if (syncUrl) {
      const url = qs.stringify(removeEmpty(this.filter), {
        encode: false,
        arrayFormat: 'comma',
        addQueryPrefix: true,
      })
      history.pushState({}, '', url)
    }
  }

  @action setFilterNonce = () => {
    const filter = { ...this.filter }
    filter.nonce = Math.random().toString(10)
    this.setFilter(filter, true)
  }

  @action resetFilters = (): void => {
    this.ResourceStore.deselectAll()
    this.setFilter(this.defaultFilter, true)
  }

  @action rejectResources = (
    resources: Resource[],
    rejectionReason?: RejectionReason,
    comment?: string,
    rating?: Rating
  ): void => {
    this.setReviewStatus({
      resources,
      reviewStatus:
        rejectionReason?.rejection_type === 'flagged'
          ? ReviewStatus.Flagged
          : ReviewStatus.Rejected,
      comment,
      rejectionReason,
      rating,
    })
  }

  @action selectRatedResources = (): void => {
    const { setSelectedResources, visibleResources } = this.ResourceStore

    if (this.reviewedResources.length) {
      const ratedIds = this.reviewedResources
        .filter(({ rating }) => rating && rating > 0)
        .map(({ resource }) => resource.id)

      setSelectedResources(
        visibleResources.filter(r => ratedIds.indexOf(r.id) > -1)
      )
    }
  }

  @action selectUnratedResources = (): void => {
    const { setSelectedResources, visibleResources } = this.ResourceStore
    let ratedResources: string[] = []

    if (this.reviewedResources.length) {
      ratedResources = this.reviewedResources
        .filter(({ rating }) => rating && rating > 0)
        .map(({ resource }) => resource.id)
    }

    setSelectedResources(
      visibleResources.filter(r => !ratedResources.includes(r.id))
    )
  }

  @action getReviewedResources = (filters: ReviewFilter[]): Resource[] => {
    return this.reviewedResources
      .filter(filterByRating(filters))
      .map(obj => obj.resource)
  }

  @action setReviewedResources = (
    reviewedResources: ReviewedResource[]
  ): void => {
    this.reviewedResources = reviewedResources
  }

  @action removeReviewStatus = (resources: Resource[]): void => {
    const ids = resources.map(x => x.id)
    this.reviewedResources = this.reviewedResources.filter(
      rev => !ids.includes(rev.resource.id)
    )
  }

  @action setReviewStatus = ({
    resources,
    reviewStatus,
    rating,
    comment,
    rejectionReason,
  }: {
    resources: Resource[]
    reviewStatus: ReviewStatus
    rating?: Rating
    comment?: string
    rejectionReason?: RejectionReason
  }): void => {
    const updated = this.reviewedResources.slice()

    resources.forEach(resource => {
      const idx = updated.findIndex(obj => obj.resource.id === resource.id)

      if (reviewStatus === ReviewStatus.Approved) {
        pushOrUpdate(updated, idx, {
          resource,
          status: reviewStatus,
          rating,
        } as ApprovedResource)
      } else if (
        reviewStatus === ReviewStatus.Flagged ||
        reviewStatus === ReviewStatus.Rejected
      ) {
        pushOrUpdate(updated, idx, {
          resource,
          status: reviewStatus,
          comment,
          rating,
          rejectionReason,
        } as RejectedResource)
      }
    })

    this.reviewedResources = updated
  }

  @action setApproveAllModalVisibility = (state: boolean): void => {
    this.approveAllModalVisibility = state
  }

  @action setRejectAllModalVisibility = (state: boolean): void => {
    this.rejectAllModalVisibility = state
  }

  @computed get selectedVisible(): Resource[] {
    const { selectedResources, visibleResources } = this.ResourceStore
    return visibleResources.filter(resource =>
      selectedResources.includes(resource.id)
    )
  }

  @computed get resourcesAreReviewed(): boolean {
    return this.reviewedResources.length > 0
  }

  @computed get queryParams(): GetApiV1ResourcesQueryParams {
    const params: GetApiV1ResourcesQueryParams = {}

    if (this.filter.file_type?.length) {
      params.file_type = this.filter.file_type
    }

    if (this.filter.category_ids?.length) {
      params.category_ids = this.filter.category_ids
    }

    if (this.filter.content_type?.length) {
      params.content_type = this.filter.content_type
    }

    if (this.filter.program_type) {
      params.program_type = this.filter
        .program_type as GetApiV1ResourcesQueryParams['program_type']
    }

    if (this.filter.license) {
      params.license = this.filter
        .license as GetApiV1ResourcesQueryParams['license']
    }

    if (this.filter.sort === Sort.Newest) {
      params.sort_direction = 'desc'
    }

    if (this.filter.sort === Sort.Oldest) {
      params.sort_direction = 'asc'
    }

    if (this.filter.history === History.With) {
      params.has_comments = true
    }

    if (this.filter.history === History.Without) {
      params.has_comments = false
    }

    if (this.filter.releases === Releases.With) {
      params.has_release = true
    }

    if (this.filter.releases === Releases.Without) {
      params.has_release = false
    }

    if (this.filter.ai_generated?.length) {
      const arr = this.filter.ai_generated

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.ai_generated = undefined
      } else if (arr.includes(BoolType.True)) {
        params.ai_generated = true
      } else {
        params.ai_generated = false
      }
    }

    if (this.filter.auto_submitted?.length) {
      const arr = this.filter.auto_submitted

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.auto_submitted = undefined
      } else if (arr.includes(BoolType.True)) {
        params.auto_submitted = true
      } else {
        params.auto_submitted = false
      }
    }

    if (this.filter.magic_metadata?.length) {
      const arr = this.filter.magic_metadata

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.magic_metadata = undefined
      } else if (arr.includes(BoolType.True)) {
        params.magic_metadata = true
      } else {
        params.magic_metadata = false
      }
    }

    if (this.filter.linked_asset === LinkedAsset.SVG) {
      params.has_svg = true
    }

    if (this.filter.linked_asset === LinkedAsset.None) {
      params.has_svg = false
    }

    return params
  }

  @action setTotalPendingCount = (total: number): void => {
    this.totalPendingCount = total
  }

  @action setTotalFilteredCount = (total: number): void => {
    this.totalFilteredCount = total
  }

  @action clearSuccessfulSubmissions = (): void => {
    this.successfullySubmittedIds = []
  }

  @action submitReviewedResources = async (
    contributorId: string,
    reviewedResources: ReviewedResource[]
  ): Promise<SubmitResponse> => {
    const approvedResources: Resource[] = []
    const flaggedResources: RejectedResource[] = []
    const rejectedResources: RejectedResource[] = []

    reviewedResources.forEach(reviewed => {
      if (reviewed.resource?.release_ids?.length) {
        reviewed.resource.release_ids = reviewed.resource.release_ids.filter(
          id => {
            if (id === null) {
              addBreadcrumb({
                category: 'submitReviewedResources',
                message: `Reviewed resource had null release_id. Resource id: ${reviewed.resource.id}`,
                level: Severity.Info,
              })
              return false
            }
            return true
          }
        )
      }

      switch (reviewed.status) {
        case ReviewStatus.Approved:
          reviewed.resource.rating = reviewed.rating
          reviewed.resource.state = 'approved'
          approvedResources.push(reviewed.resource)
          break
        case ReviewStatus.Flagged:
          flaggedResources.push(reviewed)
          break
        case ReviewStatus.Rejected:
          rejectedResources.push(reviewed)
          break

        default:
          assertExhaustive(reviewed)
      }
    })

    const promises: Promise<SubmitResponse>[] = []

    if (approvedResources.length) {
      promises.push(this.onApproveResources(approvedResources))
    }

    if (flaggedResources.length) {
      promises.push(this.onFlagResources(flaggedResources))
    }

    if (rejectedResources.length) {
      promises.push(this.onRejectResources(rejectedResources))
    }

    try {
      const values = await Promise.all(promises)
      // this.reviewedResources = []
      const successFailure = values.reduce(
        (acc, val) => {
          acc.succeededIds = acc.succeededIds.concat(val.succeededIds)
          acc.failedIds = acc.failedIds.concat(val.failedIds)
          return acc
        },
        {
          succeededIds: [],
          failedIds: [],
        }
      )

      this.successfullySubmittedIds = [...this.successfullySubmittedIds].concat(
        successFailure.succeededIds
      )
      return successFailure
    } catch (err) {
      captureError(
        err,
        'Error while submitting reviewed resources - stores/SubmittedReviewStore.ts'
      )
      throw err
    }
  }

  @action onApproveResources = async (
    resources: Resource[]
  ): Promise<SubmitResponse> => {
    const { setRefreshedResources, removeResources } = this.ResourceStore
    const resourceIds = resources.map(res => res.id)

    try {
      const { failed_ids: failedIds } = await this.api.request(
        'PATCH',
        '/api/v1/resources',
        {
          body: {
            resources,
            review_assigned_to_me: true,
          },
        }
      )
      const succeededIds = resourceIds.filter(id => !failedIds.includes(id))

      setRefreshedResources(succeededIds)
      removeResources(succeededIds)

      return { succeededIds, failedIds }
    } catch (err) {
      captureError(
        err,
        'Error while approving resources - stores/SubmittedReviewStore.ts'
      )
      throw err
    }
  }

  // TODO: Work with Aleks to resolve this API endpoint workflow
  @action onFlagResources = async (
    reviewed: RejectedResource[]
  ): Promise<SubmitResponse> => {
    const { setRefreshedResources, removeResources } = this.ResourceStore
    const flaggings = reviewed.map(rev => ({
      resource_id: rev.resource.id,
      rejection_reason_id: rev.rejectionReason.id,
      ...(rev.rating ? { rating: rev.rating } : {}),
      ...(rev.comment ? { comment_text: rev.comment } : {}),
    }))
    const ids = flaggings.map(f => f.resource_id)

    try {
      await this.api.request('PATCH', '/api/v1/admin/resources/flag', {
        body: { flaggings },
      })

      setRefreshedResources(ids)
      removeResources(ids)

      return { succeededIds: ids, failedIds: [] }
    } catch (err) {
      captureError(
        err,
        'Error while flagging resources - stores/SubmittedReviewStore.ts'
      )
      throw err
    }
  }

  // TODO: Work with Aleks to resolve this API endpoint workflow
  @action onRejectResources = async (
    reviewed: RejectedResource[]
  ): Promise<SubmitResponse> => {
    const { setRefreshedResources, removeResources } = this.ResourceStore
    const rejections = reviewed.map(rev => ({
      resource_id: rev.resource.id,
      rejection_reason_id: rev.rejectionReason.id,
      ...(rev.rating ? { rating: rev.rating } : {}),
      ...(rev.comment ? { comment_text: rev.comment } : {}),
    }))
    const ids = rejections.map(f => f.resource_id)

    try {
      await this.api.request('PATCH', '/api/v1/admin/resources/reject', {
        body: { rejections },
      })

      setRefreshedResources(ids)
      removeResources(ids)

      return { succeededIds: ids, failedIds: [] }
    } catch (err) {
      captureError(
        err,
        'Error while rejecting resources - stores/SubmittedReviewStore.ts'
      )
      throw err
    }
  }

  @action unassignResources = async (): Promise<ActionResponse> => {
    const reviewerId = this.AuthStore.session ? this.AuthStore.session.id : null

    try {
      const successful = await this.api.request(
        'PATCH',
        '/api/v1/admin/resources/unassign_all_from_reviewer',
        {
          query: { reviewer_import_guid: reviewerId },
        }
      )
      return successful
        ? ActionResponse.Successful
        : ActionResponse.Unsuccessful
    } catch (e) {
      captureError(
        e,
        'Error while unassigning resources - stores/SubmittedReviewStore.ts'
      )
      return ActionResponse.Error
    }
  }

  @action addBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.push(operation)

    this.bulkOperations = operations
  }

  @action removeBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex((op: BulkOperation) => op.id === operation.id),
      1
    )

    this.bulkOperations = operations
  }

  @action updateBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex((op: BulkOperation) => op.id === operation.id),
      1,
      operation
    )

    this.bulkOperations = operations
  }

  @action addExitedSubmittedContributor = (contributorId: string) => {
    const exitedSubmittedContributors = [...this.exitedSubmittedContributors]
    if (!this.exitedSubmittedContributors.includes(contributorId)) {
      exitedSubmittedContributors.push(contributorId)
    }
    this.exitedSubmittedContributors = exitedSubmittedContributors
  }

  @action removeExitedSubmittedContributor = (contributorId: string) => {
    const exitedSubmittedContributors = [...this.exitedSubmittedContributors]
    const index = this.exitedSubmittedContributors.indexOf(contributorId)
    if (index !== -1) {
      exitedSubmittedContributors.splice(index, 1)
    }
    this.exitedSubmittedContributors = exitedSubmittedContributors
  }
}
