import { styled } from '@mui/material/styles'
import { UsersResourceCounts } from 'api'
import { themeColor, themeSpacing, Typography } from 'eezy-components'
import React, { useMemo, useState } from 'react'
import ResourceCounter from '../../../components/SubNav/ResourceCounter'

import DownArrow from 'ez-styles/assets/icons/arrows/arrow-big-down.svg'
import UpArrow from 'ez-styles/assets/icons/arrows/arrow-big-up.svg'

type Props = {
  dest?: string
  pathname?: string
  navigate?: (route: string) => void
  label: string
  icon?: JSX.Element
  children?: ChildProps | readonly ChildProps[]
  count?: Array<keyof UsersResourceCounts>
  testId?: string
}

type ChildProps = {
  props: Props
}

type StyleProps = {
  $isActive?: boolean
  $isChild?: boolean
}

const MenuItem = ({
  dest,
  navigate,
  label,
  pathname,
  icon,
  children,
  count,
  testId,
}: Props) => {
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const isActive = useMemo(
    () => dest && pathname && dest === pathname,
    [dest, pathname]
  )

  const handleClick = () => {
    if (!children && navigate && dest) {
      navigate(dest)
    } else {
      setOpenSubMenu(!openSubMenu)
    }
  }

  const handleSubNavigate = (
    childDest: string | undefined,
    subNavigate: ((route: string) => void) | undefined
  ) => {
    if (subNavigate && childDest) {
      subNavigate(childDest)
    }
  }

  const renderArrow = () => {
    if (openSubMenu) {
      return <UpArrow className="arrow-icon" />
    } else {
      return <DownArrow className="arrow-icon" />
    }
  }

  return (
    <>
      <MenuItemWrapper
        onClick={handleClick}
        $isActive={isActive || false}
        data-testid={testId}
      >
        {icon ? icon : null}
        <StyledMenuItem variant="body1" $isActive={isActive || false}>
          {label}
        </StyledMenuItem>
        {children ? renderArrow() : null}
        {count ? <ResourceCounter count={count} variant="chip" /> : null}
      </MenuItemWrapper>
      {children && openSubMenu ? (
        <SubMenuWrapper>
          {React.Children.map(children, (child: ChildProps, idx: number) => {
            if (child.props.label) {
              return (
                <StyledMenuItem
                  key={idx}
                  variant="body1"
                  $isActive={child.props.dest === child.props.pathname}
                  onClick={() =>
                    handleSubNavigate(child.props.dest, child.props.navigate)
                  }
                  $isChild
                  data-testid={child.props.testId}
                >
                  {child.props.label}
                </StyledMenuItem>
              )
            }
          })}
        </SubMenuWrapper>
      ) : null}
    </>
  )
}

export default MenuItem

const MenuItemWrapper = styled('div')<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    fill: ${(props: StyleProps) =>
      props.$isActive
        ? themeColor('primary', 'main')
        : themeColor('secondary', 'main')};
    width: ${themeSpacing(2.5)};
    height: ${themeSpacing(2.5)};
  }

  .arrow-icon {
    width: ${themeSpacing(1.5)};
    height: ${themeSpacing(1.5)};
  }

  &:hover {
    cursor: pointer;
    color: ${themeColor('primary', 'main')};
  }
`

const StyledMenuItem = styled(Typography)<StyleProps>`
  color: ${(props: StyleProps) =>
    props.$isActive
      ? themeColor('primary', props.$isChild ? 'light' : 'main')
      : themeColor('secondary', props.$isChild ? 'light' : 'main')};
  display: block;
  padding: 1rem;
  cursor: ${(props: StyleProps) => (props.$isChild ? 'pointer' : 'inherit')};
  z-index: 9999;
`

const SubMenuWrapper = styled('div')`
  margin-left: ${themeSpacing(2.35)};
`
