import ErrorBoundary from 'components/ErrorBoundary'
import { Routes as AppRoutes } from 'constants/index'
import { LoadingSpinner } from 'eezy-components'
import { updateDatalayer } from 'helpers/gtag'
import { useViewport } from 'helpers/hooks'
import cookie from 'js-cookie'
import FullWidthLayoutWrapper from 'layouts/full-width/FullWidthLayoutWrapper'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import NotFound from 'pages/404'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { RestfulProvider } from 'restful-react'
import Nav from './nav'
import UserResourceCountGetter from './nav/user_resource_count_getter'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import SignIn from './pages/auth/SignIn'
import SessionLoader from './pages/auth/SignIn/SessionLoader'
import Verification from './pages/auth/Verification'
import Router from './routes/Router'
import { AuthStore } from './stores'
import Api, { Events, Service, ServiceTypes } from './util/api'
import { useInstance } from './util/di'

const App = (): JSX.Element => {
  const { t } = useTranslation()
  const api = useInstance<Api>('api')
  const { session } = useInstance<AuthStore>('AuthStore')
  const { enqueueSnackbar } = useSnackbar()
  const [appReady, setAppReady] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useViewport()
  const isAdmin = useMemo(() => Boolean(session?.roles.length), [session])

  useEffect(() => {
    if (session && !api.services.length) {
      setAppReady(true)
      updateDatalayer({
        email_address: session.email,
        first_name: session.firstName,
        last_name: session.lastName,
        contributor_guid: session.id,
      })

      if (window.snowplow) {
        window.snowplow('newTracker', 'sp', 'a.vecteezy.com', {
          appId: 'content_manager_production',
          discoverRootDomain: true,
          eventMethod: 'beacon',
          cookieSameSite: 'Lax',
          context: {
            webPage: true,
            performanceTiming: true,
            gaCookies: true,
            geolocation: false,
          },
        })
        window.snowplow('enableActivityTracking', {
          minimumVisitLength: 10,
          heartbeatDelay: 10,
        })
        window.snowplow('enableLinkClickTracking')

        window.snowplow('addGlobalContexts', [
          {
            schema: 'iglu:com.eezy/user_state/jsonschema/1-0-5',
            data: {
              contributorGuid: session.id,
            },
          },
        ])

        window.snowplow('trackPageView')
      }
    }
  }, [session, api.services])

  useEffect(() => {
    if (
      session &&
      !session.current_on_terms &&
      location.pathname !== AppRoutes.Terms &&
      !isAdmin
    ) {
      navigate(AppRoutes.Terms)
    }

    if (
      session &&
      session?.enabledFeatures.includes('directory_info_page') &&
      !session.directory_confirmation &&
      session.current_on_terms &&
      location.pathname !== AppRoutes.ContributorDirectoryInfo &&
      !isAdmin
    ) {
      navigate(AppRoutes.ContributorDirectoryInfo)
    }
  }, [session, location, isAdmin, navigate])

  useEffect(() => {
    const disposers = [
      api.emitter.subscribe(Events.NetworkError, ({ msg }) =>
        enqueueSnackbar(t(msg), { variant: 'error' })
      ),
    ]

    return () => disposers.forEach(disposer => disposer())
  }, [api, enqueueSnackbar, t])

  useEffect(() => {
    if (!session) {
      return
    }
    api.addService(
      new Service({
        key: ServiceTypes.CM,
        host: session.apiEndpoint,
        token: session.apiToken,
        isDefault: true,
      })
    )
  }, [api, session, session?.apiToken, session?.apiEndpoint])

  useEffect(() => {
    if (!session) {
      return
    }
    api.addService(
      new Service({
        key: ServiceTypes.Referrals,
        host: session.referralsUrl,
        token: session.referralsToken,
      })
    )
  }, [api, session, session?.referralsUrl, session?.referralsToken])

  if (!session) {
    const authToken = cookie.get(`eezy-cm-auth-token`)

    if (authToken) {
      return <SessionLoader apiToken={authToken} />
    }

    return (
      <ErrorBoundary>
        <Routes>
          {/* AUTHENTICATION ROUTES*/}
          <Route path={AppRoutes.SignIn} element={<SignIn />} />
          <Route path={AppRoutes.ForgotPassword} element={<ForgotPassword />} />
          <Route path={AppRoutes.ResetPassword} element={<ResetPassword />} />
          <Route path={AppRoutes.UnlockAccount} element={<ResetPassword />} />
          <Route path={AppRoutes.Verify} element={<Verification />} />
          <Route path="/" element={<Navigate to={AppRoutes.SignIn} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    )
  }

  if (!appReady) {
    return (
      <FullWidthLayoutWrapper>
        <LoadingSpinner />
      </FullWidthLayoutWrapper>
    )
  }

  return (
    <RestfulProvider
      base={session.apiEndpoint}
      requestOptions={() => ({
        headers: { Authorization: 'Bearer ' + session.apiToken },
      })}
      queryParamStringifyOptions={{ arrayFormat: 'brackets' }}
    >
      <UserResourceCountGetter session={session}>
        <Nav session={session} isMobile={isMobile} />
        <ErrorBoundary>
          <Router session={session} />
        </ErrorBoundary>
      </UserResourceCountGetter>
    </RestfulProvider>
  )
}

export default observer(App)
