import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'
import * as S from './styled'

type Props = {
  title: string
  active: boolean
  onChange: () => void
  children?: ReactNode
  className?: string
  testId?: string
}

const Panel = ({
  title,
  active,
  onChange,
  children,
  className,
  testId,
}: Props): JSX.Element => (
  <S.Container
    square
    elevation={0}
    expanded={active}
    onChange={onChange}
    className={className}
    data-testid={testId || 'panel'}
  >
    <S.Summary expandIcon={<S.Chevron />}>
      <Typography variant="subtitle2">{title}</Typography>
    </S.Summary>

    <S.Details>{children}</S.Details>
  </S.Container>
)

export default Panel
