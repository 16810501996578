import React, { ReactNode, MouseEvent } from 'react'
import * as S from './styled'
import { Badge } from '@mui/material'
import ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import { MenuPopper } from '../../index'

type State = {
  open: boolean
  anchorEl?: Element
  subRoutes: string[]
}

type Props = {
  navigate?: (path: string) => void
  activeTab: string | boolean
  label: string | ReactNode
  dest?: string
  count?: number | string
  onClick?: (ev: MouseEvent<HTMLElement>) => void
  className?: string
  testId?: string
}

class NavLink extends React.Component<React.PropsWithChildren<Props>, State> {
  public constructor(props: React.PropsWithChildren<Props>) {
    super(props)
    const subRoutes = React.Children.map(this.props.children, child => {
      if (React.isValidElement(child)) {
        return child.props['dest']
      }
    })

    this.state = { open: false, subRoutes: subRoutes }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChildClick = this.handleChildClick.bind(this)
    this.activeClass = this.activeClass.bind(this)
  }

  public handleClose(): void {
    this.setState({ anchorEl: null, open: false })
  }

  public handleClick(event: MouseEvent<HTMLElement>): void {
    event.preventDefault()

    this.setState({ anchorEl: event.currentTarget })

    if (this.props.children) {
      this.setState({ open: !this.state.open })
    } else if (this.props.navigate) {
      this.props.navigate(this.props.dest)
    }

    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  public handleChildClick(dest: string): void {
    this.setState({ open: false })
    this.props.navigate(dest)
  }

  public activeClass(): string {
    if (
      this.props.activeTab === true ||
      (typeof this.props.activeTab === 'string' &&
        (this.props.activeTab === this.props.dest ||
          this.state.subRoutes?.indexOf(this.props.activeTab) > -1))
    ) {
      return 'active'
    }

    return ''
  }

  public render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
        return React.cloneElement(child as React.ReactElement, {
          onClick: this.handleChildClick,
        })
      }
      return child
    })

    const { children, count, dest, label, testId, className = '' } = this.props

    return (
      <S.NavLinkRoot onClick={this.handleClick}>
        <S.StyledNavLink
          className={`${className} ${this.activeClass()}`}
          href={dest}
          data-testid={testId}
        >
          <Badge badgeContent={count || 0} color="primary" variant="dot">
            {label} {children && <ArrowSmallDown />}
          </Badge>
        </S.StyledNavLink>

        {children && (
          <MenuPopper
            open={this.state.open}
            handleClose={this.handleClose}
            anchorEl={this.state.anchorEl}
          >
            {childrenWithProps}
          </MenuPopper>
        )}
      </S.NavLinkRoot>
    )
  }
}

export default NavLink
