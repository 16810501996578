import { BulkDownloadState } from 'components/ResourceDetails/MultipleResourceActions/types'
import { Period } from 'constants/resources'
import { saveAs } from 'file-saver'
import { captureError } from 'helpers/error'
import JSZip from 'jszip'
import { FileUpload, Rating, Session } from 'types'
import { Category, GetApiV1ContributorTopResourcesQueryParams } from '../api'

export const NO_SPECIAL_CHARS = /^[A-Za-z0-9 ]*$/
export const NO_SPECIAL_CHARS_OR_SPACES = /^[A-Za-z0-9]*$/

export function filenameFromUrl(url?: string): string {
  return url?.split('/')?.pop() || ''
}

export function forceDownloadUrl(url: string | undefined): void {
  const a = document.createElement('a')
  if (url) {
    a.href = url
    a.download = filenameFromUrl(url)
    a.rel = 'noopener noreferrer'
    a.target = '_blank'
    a.click()
  }
}

const getFilesForZipDownload = async (
  url: string,
  existingFilenames: Map<string, number>
): Promise<{ filename: string; data: Blob } | undefined> => {
  const response = await fetch(url)
  const data = await response.blob()

  let filename = filenameFromUrl(url)
  const filenameIndex = existingFilenames.get(filename)
  const extension = filename.substring(filename.lastIndexOf('.') + 1)

  if (!extension || !supportedExtensions.includes(extension.toLowerCase())) {
    return
  }

  if (filenameIndex) {
    existingFilenames.set(filename, filenameIndex + 1)
    filename = `${insertBeforeLastOccurrence(
      filenameFromUrl(url),
      `.${extension}`,
      `-${filenameIndex + 1}`
    )}`
  } else {
    existingFilenames.set(filename, 1)
  }

  return { filename, data }
}

export const handleZipDownload = async (
  urls: string[]
): Promise<BulkDownloadState> => {
  const zip = new JSZip()
  const existingFilenames = new Map<string, number>()

  const remoteZips = urls.map(async url => {
    const file = await getFilesForZipDownload(url, existingFilenames)
    if (file) {
      zip.file(file.filename, file.data)
    }
  })

  return Promise.all(remoteZips)
    .then(() =>
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, `eezy-download.zip`)
      })
    )
    .then(() => BulkDownloadState.SUCCESS)
    .catch(error => {
      captureError(error, 'Error while generating files for zip')
      return BulkDownloadState.FAILURE
    })
}

export const handlePSDZipDownload = async (
  urls: (string | undefined)[],
  setDownload: (bool: boolean) => void
) => {
  const zip = new JSZip()

  const remoteZips = urls.map(async (url, index) => {
    if (url) {
      const response = await fetch(url)
      const data = await response.blob()
      setDownload(true)
      zip.file(
        `${insertBeforeLastOccurrence(
          filenameFromUrl(url),
          '.psd',
          `-${index}`
        )}`,
        data
      )

      return data
    }
    return
  })

  Promise.all(remoteZips).then(() => {
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, `eezy-download.zip`)
      setDownload(false)
    })
  })
}

export function insertBeforeLastOccurrence(
  strToSearch: string,
  strToFind: string,
  strOrNumToInsert: string | number
) {
  const n = strToSearch.lastIndexOf(strToFind)
  if (n < 0) return strToSearch
  return (
    strToSearch.substring(0, n) + strOrNumToInsert + strToSearch.substring(n)
  )
}

export function filterSpecialChars(
  values: string[],
  block_spaces = false
): string[] {
  if (block_spaces)
    return values.filter(val => !!val.match(NO_SPECIAL_CHARS_OR_SPACES))
  return values.filter(val => !!val.match(NO_SPECIAL_CHARS))
}

export const Extensions = Object.freeze({
  PHOTO: ['jpg', 'jpeg'],
  VIDEO: ['mov', 'mp4'],
  VECTOR: ['eps'],
  META: ['csv'],
  PNG: ['png'],
  PSD: ['psd'],
  ZIP: ['zip'],
})

const supportedExtensions = Object.values(Extensions).reduce(
  (acc, arr) => acc.concat(arr),
  []
)

export const getFileExtension = (type?: string): string => {
  if (!type) {
    return ''
  }

  const ext = type.split('/')[1]

  switch (type) {
    case 'video/quicktime':
      return 'mov'
    case 'application/postscript':
      return 'eps'
    case 'application/x-eps':
      return 'eps'
    case 'image/vnd.adobe.photoshop':
    case 'application/x-photoshop':
    case 'application/photoshop':
    case 'application/psd':
    case 'image/psd':
      return 'psd'
    case 'application/zip':
      return 'zip'
    case 'application/x-zip-compressed':
      return 'zip'
    case 'image/jpeg':
      return 'jpg'
    default:
      return supportedExtensions.includes(ext) ? ext : ''
  }
}

export const filterPreviews = (
  blobs: Blob[]
): { bad: FileUpload[]; good: FileUpload[] } => {
  const all: FileUpload[] = blobs.map(blob => new FileUpload(blob as File))
  const photos: FileUpload[] = []
  const otherFiles: FileUpload[] = []
  all.forEach(file => {
    const ext = file.name?.split('.').pop()
    if (ext && Extensions.PHOTO.includes(ext.toLowerCase())) {
      photos.push(file)
    } else {
      otherFiles.push(file)
    }
  })

  const goodPhotos: FileUpload[] = []
  const badPhotos: FileUpload[] = []

  photos.forEach(file => {
    const baseName: string = file.name?.split('.').slice(0, -1).join('.') || ''
    const matchingFile = otherFiles.find(f => !!f.path?.match(baseName))
    if (matchingFile) {
      badPhotos.push(file)
    } else {
      goodPhotos.push(file)
    }
  })

  return { good: otherFiles.concat(goodPhotos), bad: badPhotos }
}

export const mergeFiles = (
  files: FileUpload[],
  file: FileUpload,
  data: Record<string, unknown> = {}
): FileUpload[] => {
  const idx = files.findIndex(f => f.path === file.path)

  if (idx === -1) {
    files.push({
      name: file.name,
      path: file.path,
      type: file.type,
      file: file.file,
      ...data,
    })
  } else {
    files[idx] = { ...files[idx], ...file, ...data }
  }

  return [...files]
}

export const validateFile = (
  file: FileUpload,
  session?: Session,
  canUploadBundles?: boolean
): boolean => {
  const ext: string | undefined = file.name?.split('.').pop()
  let supported: string[] = ['csv']

  if (!session || !session.contentTypes) {
    return false
  }

  session.contentTypes.forEach(contentType => {
    switch (contentType) {
      case 'video': {
        supported = supported.concat(Extensions.VIDEO)
        break
      }

      case 'vector': {
        supported = supported.concat(Extensions.VECTOR)
        break
      }

      case 'photo': {
        supported = supported.concat(Extensions.PHOTO)
        break
      }

      case 'png': {
        supported = supported.concat(Extensions.PNG)
        break
      }

      case 'psd': {
        supported = supported.concat(Extensions.PSD)
        break
      }
    }
  })

  if (canUploadBundles) {
    supported = supported.concat(Extensions.ZIP)
  }

  return ext ? supported.includes(ext.toLowerCase()) : false
}

export function isFileTypeDisallowed(file: FileUpload, session?: Session) {
  const disabledCategories = session?.disabled_categories
  const extension = getFileExtension(file.type)
  if (disabledCategories && extension) {
    return disabledCategories.some(category => {
      return (category as unknown as Category).file_types.includes(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extension as any
      )
    })
  }
  return false
}

export function checkSpecialChars(
  values: string[],
  includeSpaces = false
): boolean {
  const pattern = includeSpaces ? NO_SPECIAL_CHARS_OR_SPACES : NO_SPECIAL_CHARS
  let hasSpecChars = false
  values.forEach(s => {
    if (!s.match(pattern)) {
      hasSpecChars = true
      return
    }
  })
  return hasSpecChars
}

export function assertExhaustive(
  value: never,
  message = 'Reached unexpected case in exhaustive switch'
): never {
  throw new Error(message)
}

export function pushOrUpdate<T>(dataList: T[], key: number, data: T): void {
  if (key === -1) {
    dataList.push(data)
  } else {
    dataList[key] = data
  }
}

export function toRating(num: number): Rating {
  if (num <= 1) {
    return 1
  }

  if (num >= 5) {
    return 5
  }

  return num as Rating
}

const ifLessThanOrExtra =
  (per: number, withExtra: number) =>
  (group: number, current: number): boolean =>
    current <= per * group ||
    (group <= withExtra
      ? current <= (per + 1) * group
      : current <= per * group + withExtra)

export function orderedGroup<T>(data: T[], count: number): T[][] {
  let currentGroup = 0
  const perGroup = Math.floor(data.length / count)
  const groupsWithExtra = data.length % count
  const ifInGroup = ifLessThanOrExtra(perGroup, groupsWithExtra)

  return data.reduce(
    (acc, item, i) => {
      if (!ifInGroup(currentGroup + 1, i + 1)) {
        currentGroup += 1
      }

      acc[currentGroup].push(item)
      return acc
    },
    Array.from(Array(count), () => []) as T[][]
  )
}

export const getNavHeight = ({
  includeSubNav,
}: {
  includeSubNav?: boolean
} = {}): string => (includeSubNav ? '140px' : '80px')

/* eslint-disable-next-line */
export function noop(): void {}

export interface Stringable {
  toString(): string
}

export function isStringable(value: unknown): value is Stringable {
  return !!value && !!(value as Stringable).toString()
}

export function isRecord(u: unknown): u is Record<string, unknown> {
  return typeof u === 'object'
}

export const convertPeriodToTopResources = (
  value?: Period
): GetApiV1ContributorTopResourcesQueryParams['period'] => {
  switch (value) {
    case Period.MTD:
      return 'month'
    case Period.YTD:
      return 'current_year'
    case Period.Lifetime:
      return 'all_time'
    case Period.HalfYear:
      return 'prev_six_months'
    case Period.Quarter:
      return 'prev_three_months'
    default:
      return 'month'
  }
}

export const convertFilterToMetric = (
  value?: string
): GetApiV1ContributorTopResourcesQueryParams['metric_type'] => {
  switch (value) {
    case 'downloads' || 'earnings' || 'views':
      return value
    default:
      return 'downloads'
  }
}

export const arraysIntersect = (
  arrayA: unknown[],
  arrayB: unknown[]
): boolean => {
  const intersections = arrayA.filter(x => {
    return arrayB.includes(x)
  })

  return intersections.length > 0
}

export const isValidUrl = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  )
  return pattern.test(str)
}

export const isValidSocialMediaUrl = (urlString: string | undefined) => {
  let url: URL
  if (urlString) {
    try {
      url = new URL(urlString)
    } catch (_) {
      return false
    }
  } else {
    return true
  }

  if (!url.pathname || url.pathname === '/') {
    return false
  }

  return isValidUrl(urlString)
}

export const isValidHttpUrl = (urlString: string | undefined) => {
  let url: URL
  if (urlString) {
    try {
      url = new URL(urlString)
    } catch (_) {
      return false
    }
  } else {
    return true
  }

  if (!url.hostname) {
    return false
  }

  return isValidUrl(urlString)
}

export const isValidEmail = (email: string) => {
  const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regEx.test(email)
}

export const arrayStringChecker = (array: string[], value: string) =>
  array.some(element =>
    value.toLocaleLowerCase().includes(element.toLocaleLowerCase())
  )

export const checkPasswordSecurity = (password: string) => {
  const errors = []

  // Check password length
  if (password.length < 8) {
    errors.push('util.auth.password_character_length')
  }

  // Define regex patterns for each character category
  const uppercasePattern = /[A-Z]/
  const lowercasePattern = /[a-z]/
  const numberPattern = /[0-9]/
  const nonAlphabeticPattern = /[^A-Za-z0-9]/

  // Check for each character type and add specific error message if not present
  if (!uppercasePattern.test(password)) {
    errors.push('util.auth.password_uppercase')
  }
  if (!lowercasePattern.test(password)) {
    errors.push('util.auth.password_lowercase')
  }
  if (!numberPattern.test(password)) {
    errors.push('util.auth.password_numbers')
  }
  if (!nonAlphabeticPattern.test(password)) {
    errors.push('util.auth.password_symbols')
  }

  return errors.length > 0 ? errors : []
}
