import { styled } from '@mui/system'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import _Chevron from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import { themeColor, themeSpacing } from '../../helpers/themeHelpers'

export const Container = styled(Accordion)`
  border-bottom: 1px solid ${themeColor('secondary', 'veryLight')};

  &.Mui-expanded {
    margin: 0;
  }

  &::before {
    display: none;
  }
`

export const Summary = styled(AccordionSummary)`
  padding: 0 ${themeSpacing(3)};
`

export const Details = styled(AccordionDetails)`
  display: block;
  padding: 0 ${themeSpacing(3)} ${themeSpacing(2)} ${themeSpacing(3)};
`

export const Chevron = styled(_Chevron)`
  width: ${themeSpacing(1)};
  height: ${themeSpacing(1)};
`
