import { Select } from '@mui/material'
import { styled } from '@mui/system'
import _ArrowSmallDown from 'ez-styles/assets/icons/arrows/arrow-small-down.svg'
import { themeSpacing, themeColor, themeTypography } from '../../../helpers'

export const StyledSelect = styled(Select)`
  && {
    align-items: center;
  }
`

export const TextInputWrapper = styled('div')`
  margin: ${themeSpacing(2)};
`

export const ArrowSmallDown = styled(_ArrowSmallDown)`
  && {
    height: 5px;
    flex-basis: auto;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 0;
    right: ${themeSpacing(2)};
    top: auto;
    width: ${themeSpacing(1)};
  }
`

export const ErrorMessage = styled('div')`
  color: ${themeColor('error')};
  font-size: ${themeTypography('caption', 'fontSize')};
  height: ${themeSpacing(1)};
  margin-top: ${themeSpacing(0.25)};
`
