import { styled } from '@mui/system'
import { Checkbox, FormControlLabel } from '@mui/material'
import { themeBorderRadius, themeColor, themeSpacing } from '../../../helpers'
import _CheckmarkIcon from 'ez-styles/assets/icons/checkmarks/checkmark-small.svg'

export const Icon = styled('span')`
  border-radius: ${themeBorderRadius('tiny')};
  width: ${themeSpacing(2.5)};
  height: ${themeSpacing(2.5)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor('secondary', 'veryLight')};
`

export const CheckedIcon = styled(Icon)`
  background-color: ${themeColor('primary', 'main')};
`

export const CheckmarkIcon = styled(_CheckmarkIcon)`
  fill: #fff;
  height: 7px;
  position: relative;
  top: 1px;
  width: 10px;
`

export const StyledCheckbox = styled(Checkbox)`
  &&.MuiButtonBase-root {
    padding: 0;
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  && .MuiFormControlLabel-label {
    margin-left: ${themeSpacing(1.5)};
  }
`
