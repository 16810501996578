import { styled } from '@mui/material/styles'
import { usePostApiV1Session } from 'api'
import LogoHeader from 'components/LogoHeader'
import { Routes } from 'constants/index'
import {
  Box,
  Button,
  Center,
  Stack,
  TextInput,
  themeSpacing,
  Typography,
} from 'eezy-components'
import { captureError, getErrorMsg } from 'helpers/error'
import { gevent } from 'helpers/gtag'
import { useViewport } from 'helpers/hooks'
import cookie from 'js-cookie'
import { useSnackbar } from 'notistack'
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { AuthStore } from 'stores'
import { ApiError } from 'types'
import { useInstance } from 'util/di'
import * as S from '../styled'

/**
 * Interpolated translations
 *
 * i18n-tasks-use t('util.auth.failed_attempts_warning')
 */
const SignIn = () => {
  const { t } = useTranslation()
  const { isMobile } = useViewport()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { setSession, setTempSession } = useInstance<AuthStore>('AuthStore')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const [classname, setClassname] = useState<string | undefined>(undefined)
  const { mutate: loginUser } = usePostApiV1Session({})

  const onChangeEmail: ChangeEventHandler<HTMLInputElement> = ev =>
    setEmail(ev.target.value)
  const onChangePassword: ChangeEventHandler<HTMLInputElement> = ev =>
    setPassword(ev.target.value)

  const isValid = useMemo(() => {
    return !!email && !!password
  }, [email, password])

  const getFailedPasswordWarning = () => (
    <div>
      <Trans
        i18nKey={'util.auth.failed_attempts_warning'}
        components={{
          url: (
            <a href={Routes.ForgotPassword} target="_blank" rel="noreferrer" />
          ),
        }}
      />
    </div>
  )

  const showErrorMsg = (err?: ApiError) => {
    setClassname('nudge')

    const errorMessage = getErrorMsg(err)
    let snackbarMessage: ReactNode = ''

    if (errorMessage === t('util.auth.invalid_email_or_password')) {
      snackbarMessage = getFailedPasswordWarning()
    } else {
      snackbarMessage = t(errorMessage || 'errors.requests.generic')
    }
    enqueueSnackbar(snackbarMessage, {
      variant: 'error',
    })

    setTimeout(() => setClassname(undefined), 1000)
  }

  const onPasswordKey: KeyboardEventHandler<HTMLInputElement> = ev => {
    if (ev.key === 'Enter' && isValid) {
      onLogin()
    }
  }

  const onLogin = async () => {
    setLoggingIn(true)

    try {
      const userSession = await loginUser({ user: { email, password } })

      const needsVerification =
        userSession &&
        userSession.enabled_features.includes('verification_auth_code') &&
        userSession.needs_verification &&
        userSession.roles.length &&
        !userSession.roles.includes('bundles_contributor')

      if (needsVerification) {
        setTempSession(userSession)
        navigate(Routes.Verify)
      } else {
        cookie.set(`eezy-cm-auth-token`, userSession.api_token)
        setSession(userSession)
        setLoggingIn(false)
        enqueueSnackbar(t('util.success.sign_in'), {
          variant: 'success',
          autoHideDuration: 1500,
        })
        gevent('form_submit', 'Form-sign_in', {
          form_name: 'login',
          form_destination: '/sign_in',
        })
        gevent('sign_in_complete', 'Sign In')
      }
    } catch (err) {
      showErrorMsg(err as ApiError)
      captureError(err, 'Error while signing in - auth/SignIn/index.tsx')
      setLoggingIn(false)
      gevent('form_error', 'Form-sign_in', {
        form_name: 'login',
        form_destination: '/sign_in',
        error: getErrorMsg(err),
      })
    }
  }

  const handleSignUp = () => {
    gevent('application_landing_page', 'Application Landing')
    window.open('https://www.vecteezy.com/contributors')
  }

  return (
    <S.CenteredWrapper>
      <S.Container padding={4} $isMobile={isMobile}>
        <Stack space={4}>
          <LogoHeader centered />

          <Center>
            <S.Header variant="h5">{t('util.sign_in')}</S.Header>
          </Center>

          <FormFields className={classname}>
            <TextInput
              fullWidth
              required
              label={t('util.email')}
              aria-label={t('util.email')}
              value={email || ''}
              onChange={onChangeEmail}
              data-testid="auth-email-input"
            />

            <TextInput
              fullWidth
              required
              type="password"
              label={t('util.auth.password')}
              aria-label={t('util.auth.password')}
              value={password || ''}
              onChange={onChangePassword}
              onKeyDown={onPasswordKey}
              data-testid="auth-password-input"
            />
            <Link to={Routes.ForgotPassword} className="secondary-link">
              {t('util.auth.forgot_password')}
            </Link>
          </FormFields>

          <Box padding={0}>
            <Button
              color="primary"
              onClick={onLogin}
              loading={loggingIn}
              data-testid="login-button"
              disabled={!isValid || loggingIn}
              fullWidth
            >
              {t('util.auth.log_in')}
            </Button>
          </Box>

          <S.TextWrapper>
            <Typography variant="body1" color="secondary.main">
              {t('util.auth.not_contributor')}
            </Typography>
            <S.Link onClick={handleSignUp} className="secondary-link">
              {t('util.sign_up')}
            </S.Link>
          </S.TextWrapper>
        </Stack>
      </S.Container>
    </S.CenteredWrapper>
  )
}

export default SignIn

const FormFields = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing(2)};

  &.nudge {
    position: relative;
    animation: nudge 0.5s;
  }

  @keyframes nudge {
    0% {
      left: 0;
    }
    33% {
      left: ${themeSpacing(2)};
    }
    66% {
      left: -${themeSpacing(2)};
    }
    100% {
      left: 0;
    }
  }
`
