export enum Routes {
  Root = '/',
  Profile = '/profile',
  Referrals = '/referrals',
  Submitted = '/submitted',
  Flagged = '/flagged',
  Assets = '/assets',
  Insights = '/insights',
  InsightsVecteezy = '/insights/vecteezy',
  InsightsYour = '/insights/your',
  InsightsCommunity = '/insights/community',
  Payments = '/payments',
  Earnings = '/earnings',
  EarningsCharges = '/earnings/charges',
  EarningsInvoices = '/earnings/invoices',
  EarningsAccounts = '/earnings/accounts',
  EarningsPayouts = '/earnings/payouts',
  Portfolio = '/portfolio',
  PortfolioUpload = '/portfolio/upload',
  PortfolioAddData = '/portfolio/add_data',
  PortfolioApproved = '/portfolio/approved',
  PortfolioNotApproved = '/portfolio/not_approved',
  PortfolioPendingReview = '/portfolio/pending_review',
  Tax = '/tax',
  TaxForms = '/tax/forms',
  TaxFormsW9 = '/tax/forms/w9',
  TaxFormsW8BEN = '/tax/forms/w8ben',
  TaxFormsW8BENE = '/tax/forms/w8bene',
  TaxSuccess = '/tax/success',
  TaxSubmission = '/tax/submission',
  TaxQuestionnaire = '/tax/questionnaire',
  Terms = '/terms',
  AdminUsers = '/admin/users',
  AdminPendingUsers = '/admin/pending_users',
  SignIn = '/sign_in',
  ForgotPassword = '/forgot_password',
  ResetPassword = '/reset_password',
  UnlockAccount = '/unlock_account',
  Trestle = '/admin/trestle',
  Flipper = '/admin/flipper',
  Sidekiq = '/admin/sidekiq',
  ContributorDirectoryInfo = '/directory_info',
  ContributorDirectoryInformation = '/directory_information',
  Verify = '/verify',
}

export enum ActionResponse {
  Successful,
  Unsuccessful,
  Error,
}

export * from './challenges'
export * from './filters'
export * from './id-verification'
export * from './payments'
export * from './resources'
