import SuspensePageLoader from 'components/SuspensePageLoader'
import { Routes as AppRoutes } from 'constants/index'
import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Session } from 'types'
import NotFound from '../pages/404'

const Assets = lazy(() => import('pages/assets'))
const Insights = lazy(() => import('pages/insights'))
const Earnings = lazy(() => import('pages/earnings'))
const Payments = lazy(() => import('pages/payments'))
const Submitted = lazy(() => import('pages/submitted'))
const Flagged = lazy(() => import('pages/flagged'))
const Portfolio = lazy(() => import('pages/portfolio'))
const Dashboard = lazy(() => import('pages/dashboard'))
const Profile = lazy(() => import('pages/profile'))
const Tax = lazy(() => import('pages/tax'))
const Referrals = lazy(() => import('pages/referrals'))
const AdminUserRoot = lazy(() => import('pages/admin/users'))
const AdminPendingUsers = lazy(() => import('pages/admin/pending-users'))
const WODashboard = lazy(() => import('pages/wo/dashboard'))
const UtilFrame = lazy(() => import('pages/admin/UtilFrame'))
const Terms = lazy(() => import('pages/terms'))
const ContributorDirectoryInfo = lazy(
  () => import('pages/contributor-directory-info')
)

type Props = {
  session: Session
}

const Router = ({ session }: Props) => {
  const loadable = (Component: React.ReactNode) => (
    <SuspensePageLoader>{Component}</SuspensePageLoader>
  )

  // Determine the root route based on session state
  let rootRouteElement
  if (session.isAdmin) {
    rootRouteElement = <Navigate to="/submitted" replace />
  } else if (session.wo) {
    rootRouteElement = loadable(<WODashboard />)
  } else {
    rootRouteElement = loadable(<Dashboard />)
  }

  return (
    <Routes>
      <Route path="/" element={rootRouteElement} />
      <Route path={AppRoutes.SignIn} element={<Navigate to="/" replace />} />
      <Route path={AppRoutes.Referrals} element={loadable(<Referrals />)} />
      <Route path={AppRoutes.Assets} element={loadable(<Assets />)} />
      {/* Using the "/*" wildcard to make sure children routes render for React Router v6 */}
      <Route
        path={`${AppRoutes.Submitted}/*`}
        element={loadable(<Submitted />)}
      />
      <Route path={`${AppRoutes.Flagged}/*`} element={loadable(<Flagged />)} />
      <Route
        path={`${AppRoutes.Insights}/*`}
        element={loadable(<Insights />)}
      />
      <Route
        path={`${AppRoutes.Earnings}/*`}
        element={loadable(<Earnings />)}
      />
      <Route
        path={`${AppRoutes.Payments}/*`}
        element={loadable(<Payments />)}
      />
      <Route
        path={`${AppRoutes.Portfolio}/*`}
        element={loadable(<Portfolio />)}
      />
      <Route path={AppRoutes.Profile} element={loadable(<Profile />)} />
      <Route path={`${AppRoutes.Tax}/*`} element={loadable(<Tax />)} />
      <Route
        path={`${AppRoutes.AdminUsers}/*`}
        element={loadable(<AdminUserRoot />)}
      />
      <Route
        path={`${AppRoutes.Trestle}/*`}
        element={loadable(<UtilFrame />)}
      />
      <Route
        path={`${AppRoutes.Sidekiq}/*`}
        element={loadable(<UtilFrame />)}
      />
      <Route
        path={`${AppRoutes.Flipper}/*`}
        element={loadable(<UtilFrame />)}
      />
      <Route
        path={AppRoutes.AdminPendingUsers}
        element={loadable(<AdminPendingUsers />)}
      />
      <Route path={AppRoutes.Terms} element={loadable(<Terms />)} />
      <Route
        path={AppRoutes.ContributorDirectoryInfo}
        element={loadable(<ContributorDirectoryInfo />)}
      />

      {/* Additional route setup for marketing purposes */}
      <Route
        path={AppRoutes.ContributorDirectoryInformation}
        element={loadable(<ContributorDirectoryInfo />)}
      />

      {/* Fallback Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Router
