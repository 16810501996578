import * as Sentry from '@sentry/react'
import nacl_factory from 'js-nacl'
import process from 'process'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { removeChildErrorHandler } from 'util/errorHandlers'
import {
  AddDataStore,
  AllAssetsStore,
  ApprovedStore,
  AuthStore,
  EarningsAccountsStore,
  EarningsChargesStore,
  EarningsInvoicesStore,
  EarningsPayoutsStore,
  FlaggedReviewStore,
  FTPStore,
  NavStore,
  NotApprovedStore,
  PendingReviewStore,
  ReferralsStore,
  ReleaseStore,
  ResourceStore,
  SubmittedReviewStore,
  UploadStore,
} from '../src/stores'
import Api from '../src/util/api'
import { DIContainer, DIInstances, DIProvider } from '../src/util/di'
import MapsService from '../src/util/maps'
import MessageCollection from '../src/util/messages'

import App from './app'

import { Slide } from '@mui/material'
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LoadingSpinner, themeOptions } from 'eezy-components'
import i18n from 'i18next'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'
import TagManager from 'react-gtm-module'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import locales from '../src/locales.json'
import './index.scss'
const sentryDSN = process.env.REACT_APP_SENTRY_DSN
const isDevelopment = process.env.NODE_ENV === 'development'
const theme = createTheme(themeOptions)
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM || '',
}

// Google Tag Manager
if (!isDevelopment && tagManagerArgs.gtmId) {
  TagManager.initialize(tagManagerArgs)
}

// Sentry
if (!isDevelopment && sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.NODE_ENV,
    autoSessionTracking: false,
    // Add recommended exhaustive list of Sentry ignores
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /eezy-content-manager\.work\//i,
    ],
  })
}

// DI
const container = new DIContainer<DIInstances>({
  instances: {
    api: new Api(),
    maps: new MapsService(),
    messages: new MessageCollection(),
    nacl: false,
  },
  stores: {
    AuthStore,
    NavStore,
    ReleaseStore,
    ResourceStore,
    FTPStore,
    UploadStore,
    AddDataStore,
    ApprovedStore,
    AllAssetsStore,
    NotApprovedStore,
    FlaggedReviewStore,
    PendingReviewStore,
    SubmittedReviewStore,
    ReferralsStore,
    EarningsChargesStore,
    EarningsInvoicesStore,
    EarningsAccountsStore,
    EarningsPayoutsStore,
  },
})

// provide nacl to DI after it instantiates - currently unused but will be sooon
nacl_factory
  .instantiate(nacl => {
    container.replace('nacl', nacl)
  })
  .catch()

const render = function () {
  const appRoot = document.createElement('div')
  appRoot.id = 'cm-ng'
  document.body.prepend(appRoot)
  const root = createRoot(appRoot!)
  // initialize i18next
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: locales,
      lng: 'en',
      fallbackLng: 'en',
      keySeparator: '.',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }

  // Fixes Google translate error
  // TODO: Fix specific error that is getting thrown to console when using google translate
  removeChildErrorHandler()

  root.render(
    <DIProvider container={container}>
      <React.Suspense fallback={<LoadingSpinner />}>
        <I18nextProvider i18n={i18n}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                  maxSnack={2}
                  anchorOrigin={anchorOrigin}
                  autoHideDuration={4000}
                  TransitionComponent={Slide}
                >
                  <Router>
                    <App />
                  </Router>
                </SnackbarProvider>
              </LocalizationProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </I18nextProvider>
      </React.Suspense>
    </DIProvider>
  )

  document.removeEventListener('DOMContentLoaded', render, false)
}

if (document.readyState !== 'loading') {
  render()
} else {
  document.addEventListener('DOMContentLoaded', render)
}
